'use client'
import React, {useEffect, useState} from "react";
import {Adsense} from "@ctrl/react-adsense/dist/adsense";

export const HeroAdBanner = () => {

    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, []);

    {
        if (windowSize.width < 1024) {
            return <div></div>;
        }
    }

    return (
        <>
            <div className="hidden md:block  pb-4">
                <div
                    className="flex  flex-col  m-auto"
                    style={{
                        width: 728,
                        //   height: "110px",
                    }}
                >
                    <div className="flex w-full">
          <span className="block text-xs" style={{color: "#555"}}>
            Advertisement
          </span>
                    </div>

                    <Adsense
                        className="  "
                        style={{
                            height: 90,
                            background: "#eeeeee33",
                        }}
                        // adTest="on"
                        client="ca-pub-7908993773401181"
                        slot="9058835395"
                        format="horizontal"
                        // responsive="true"
                    />
                </div>
            </div>
        </>
    );
};
