import {ISVG} from "../../common/Logo/Logo";

export interface ICircle1Props extends Pick<ISVG, "fill"> {}

export const Circle1 = ({ fill }: ICircle1Props) => {
    const _fill = fill ?? "#FFEAD8";

    return (
        <svg
            width="123"
            height="122"
            viewBox="0 0 123 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.2136 0.0608148C81.6358 -0.961892 109.793 10.8985 119.045 36.8426C129.087 65.0011 119.629 97.5592 94.9579 114.315C72.3228 129.688 43.1251 119.973 21.6749 102.973C3.04644 88.2091 -4.49984 63.8963 2.68186 41.1823C10.0226 17.9655 29.9741 0.96482 54.2136 0.0608148Z"
                fill={_fill}
            />
        </svg>
    );
};
