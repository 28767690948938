'use client'
import {FC} from "react";
import FileUploader from "./FileUploader";
import {logFirebaseEvent} from "../../../helpers/firebase_helper";
import {readFile} from "../../../helpers/file_picker";
import {useRouter} from "next/router";

interface iProps {
  routeName: string;
  location: string;
  children: any;
}

const fileTypes = ["JPG", "PNG", "JPEG", "GIF", "WEBP"];

export const FileUploadDropZone: FC<iProps> = ({
  routeName,
  location,
  children,
}) => {
  const router = useRouter();
  async function handleChange(inputFiles: any) {
    // console.log(inputFiles);
    const files = await processFiles(inputFiles);
    (window as any).state = {
      selectedFiles: files,
    };

    const iframe = document.getElementById("cm_iframe") as any;
    if (iframe != null) {
      iframe.contentWindow.postMessage({ fileLoaded: true }, "*");
    }
    const isNotEmpty = files.length != 0;

    if (isNotEmpty) {
      await router.push(routeName);

      logFirebaseEvent("cus_select_photos", {
        location: location,
      });
    } else {
      console.log("Got empty list");
    }
  }

  async function processFiles(files: any): Promise<string[]> {
    const selectedFiles: string[] = [];
    return new Promise(async (resolve, reject) => {
      try {
        for (const file of files) {
          const fileData = await readFile(file);
          selectedFiles.push(fileData as any);
        }
        resolve(selectedFiles);
      } catch (e) {
        console.log(e);
      }
    });
  }

  return (
    <FileUploader
      multiple={true}
      hoverTitle="Drop image files here"
      handleChange={(v) => handleChange(v)}
      name="file"
      types={fileTypes}
    >
      {children as any}
    </FileUploader>
  );
};
