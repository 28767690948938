import {ISVG} from "../../common/Logo/Logo";

export interface IShape1Props extends Pick<ISVG, "fill"> {}

export const Shape1 = ({ fill }: IShape1Props) => {
    const _fill = fill ?? "#F2994A";

    return (
        <svg
            width="154"
            height="151"
            viewBox="0 0 154 151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.7352 0.122643C95.5541 -0.715857 118.584 2.49616 134.23 17.5292C150.032 32.7121 154.194 55.4717 153.993 77.2369C153.794 98.7677 149.137 121.229 133.136 135.864C117.566 150.105 94.9189 151.981 73.7352 150.64C54.1153 149.399 35.3283 142.586 21.4929 128.799C7.72363 115.079 0.7417 96.537 0.0697575 77.2369C-0.628725 57.1744 3.82207 36.6105 17.7747 22.0046C32.0835 7.026 52.8931 0.923604 73.7352 0.122643Z"
                fill={_fill}
            />
        </svg>
    );
};
