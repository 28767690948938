import {ISVG} from "../../common/Logo/Logo";

export interface IDotsProps extends Pick<ISVG, "fill"> {}

export const Dots = ({ fill }: IDotsProps) => {
    const _fill = fill ?? "#F2994A";
    return (
        <svg
            width="184"
            height="164"
            viewBox="0 0 184 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2" cy="2" r="2" fill={_fill} />
            <circle cx="20" cy="2" r="2" fill={_fill} />
            <circle cx="38" cy="2" r="2" fill={_fill} />
            <circle cx="56" cy="2" r="2" fill={_fill} />
            <circle cx="74" cy="2" r="2" fill={_fill} />
            <circle cx="92" cy="2" r="2" fill={_fill} />
            <circle cx="110" cy="2" r="2" fill={_fill} />
            <circle cx="128" cy="2" r="2" fill={_fill} />
            <circle cx="146" cy="2" r="2" fill={_fill} />
            <circle cx="164" cy="2" r="2" fill={_fill} />
            <circle cx="182" cy="2" r="2" fill={_fill} />
            <circle cx="2" cy="20" r="2" fill={_fill} />
            <circle cx="20" cy="20" r="2" fill={_fill} />
            <circle cx="38" cy="20" r="2" fill={_fill} />
            <circle cx="56" cy="20" r="2" fill={_fill} />
            <circle cx="74" cy="20" r="2" fill={_fill} />
            <circle cx="92" cy="20" r="2" fill={_fill} />
            <circle cx="110" cy="20" r="2" fill={_fill} />
            <circle cx="128" cy="20" r="2" fill={_fill} />
            <circle cx="146" cy="20" r="2" fill={_fill} />
            <circle cx="164" cy="20" r="2" fill={_fill} />
            <circle cx="182" cy="20" r="2" fill={_fill} />
            <circle cx="2" cy="37" r="2" fill={_fill} />
            <circle cx="20" cy="37" r="2" fill={_fill} />
            <circle cx="38" cy="37" r="2" fill={_fill} />
            <circle cx="56" cy="37" r="2" fill={_fill} />
            <circle cx="74" cy="37" r="2" fill={_fill} />
            <circle cx="92" cy="37" r="2" fill={_fill} />
            <circle cx="110" cy="37" r="2" fill={_fill} />
            <circle cx="128" cy="37" r="2" fill={_fill} />
            <circle cx="146" cy="37" r="2" fill={_fill} />
            <circle cx="164" cy="37" r="2" fill={_fill} />
            <circle cx="182" cy="37" r="2" fill={_fill} />
            <circle cx="2" cy="55" r="2" fill={_fill} />
            <circle cx="20" cy="55" r="2" fill={_fill} />
            <circle cx="38" cy="55" r="2" fill={_fill} />
            <circle cx="56" cy="55" r="2" fill={_fill} />
            <circle cx="74" cy="55" r="2" fill={_fill} />
            <circle cx="92" cy="55" r="2" fill={_fill} />
            <circle cx="110" cy="55" r="2" fill={_fill} />
            <circle cx="128" cy="55" r="2" fill={_fill} />
            <circle cx="146" cy="55" r="2" fill={_fill} />
            <circle cx="164" cy="55" r="2" fill={_fill} />
            <circle cx="182" cy="55" r="2" fill={_fill} />
            <circle cx="2" cy="73" r="2" fill={_fill} />
            <circle cx="20" cy="73" r="2" fill={_fill} />
            <circle cx="38" cy="73" r="2" fill={_fill} />
            <circle cx="56" cy="73" r="2" fill={_fill} />
            <circle cx="74" cy="73" r="2" fill={_fill} />
            <circle cx="92" cy="73" r="2" fill={_fill} />
            <circle cx="110" cy="73" r="2" fill={_fill} />
            <circle cx="128" cy="73" r="2" fill={_fill} />
            <circle cx="146" cy="73" r="2" fill={_fill} />
            <circle cx="164" cy="73" r="2" fill={_fill} />
            <circle cx="182" cy="73" r="2" fill={_fill} />
            <circle cx="2" cy="91" r="2" fill={_fill} />
            <circle cx="20" cy="91" r="2" fill={_fill} />
            <circle cx="38" cy="91" r="2" fill={_fill} />
            <circle cx="56" cy="91" r="2" fill={_fill} />
            <circle cx="74" cy="91" r="2" fill={_fill} />
            <circle cx="92" cy="91" r="2" fill={_fill} />
            <circle cx="110" cy="91" r="2" fill={_fill} />
            <circle cx="128" cy="91" r="2" fill={_fill} />
            <circle cx="146" cy="91" r="2" fill={_fill} />
            <circle cx="164" cy="91" r="2" fill={_fill} />
            <circle cx="182" cy="91" r="2" fill={_fill} />
            <circle cx="2" cy="109" r="2" fill={_fill} />
            <circle cx="20" cy="109" r="2" fill={_fill} />
            <circle cx="38" cy="109" r="2" fill={_fill} />
            <circle cx="56" cy="109" r="2" fill={_fill} />
            <circle cx="74" cy="109" r="2" fill={_fill} />
            <circle cx="92" cy="109" r="2" fill={_fill} />
            <circle cx="110" cy="109" r="2" fill={_fill} />
            <circle cx="128" cy="109" r="2" fill={_fill} />
            <circle cx="146" cy="109" r="2" fill={_fill} />
            <circle cx="164" cy="109" r="2" fill={_fill} />
            <circle cx="182" cy="109" r="2" fill={_fill} />
            <circle cx="2" cy="127" r="2" fill={_fill} />
            <circle cx="20" cy="127" r="2" fill={_fill} />
            <circle cx="38" cy="127" r="2" fill={_fill} />
            <circle cx="56" cy="127" r="2" fill={_fill} />
            <circle cx="74" cy="127" r="2" fill={_fill} />
            <circle cx="92" cy="127" r="2" fill={_fill} />
            <circle cx="110" cy="127" r="2" fill={_fill} />
            <circle cx="128" cy="127" r="2" fill={_fill} />
            <circle cx="146" cy="127" r="2" fill={_fill} />
            <circle cx="164" cy="127" r="2" fill={_fill} />
            <circle cx="182" cy="127" r="2" fill={_fill} />
            <circle cx="2" cy="145" r="2" fill={_fill} />
            <circle cx="20" cy="145" r="2" fill={_fill} />
            <circle cx="38" cy="145" r="2" fill={_fill} />
            <circle cx="56" cy="145" r="2" fill={_fill} />
            <circle cx="74" cy="145" r="2" fill={_fill} />
            <circle cx="92" cy="145" r="2" fill={_fill} />
            <circle cx="110" cy="145" r="2" fill={_fill} />
            <circle cx="128" cy="145" r="2" fill={_fill} />
            <circle cx="146" cy="145" r="2" fill={_fill} />
            <circle cx="164" cy="145" r="2" fill={_fill} />
            <circle cx="182" cy="145" r="2" fill={_fill} />
            <circle cx="2" cy="162" r="2" fill={_fill} />
            <circle cx="20" cy="162" r="2" fill={_fill} />
            <circle cx="38" cy="162" r="2" fill={_fill} />
            <circle cx="56" cy="162" r="2" fill={_fill} />
            <circle cx="74" cy="162" r="2" fill={_fill} />
            <circle cx="92" cy="162" r="2" fill={_fill} />
            <circle cx="110" cy="162" r="2" fill={_fill} />
            <circle cx="128" cy="162" r="2" fill={_fill} />
            <circle cx="146" cy="162" r="2" fill={_fill} />
            <circle cx="164" cy="162" r="2" fill={_fill} />
            <circle cx="182" cy="162" r="2" fill={_fill} />
        </svg>
    );
};
