import {ISVG} from "../../common/Logo/Logo";

export interface IShape2Props extends Pick<ISVG, "fill"> {}

export const Shape2 = ({ fill }: IShape2Props) => {
    const _fill = fill ?? "#FFE6DO";

    return (
        <svg
            width="130"
            height="129"
            viewBox="0 0 130 129"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.244 0.104314C80.6626 -0.608875 100.103 2.12312 113.311 14.9096C126.65 27.8235 130.164 47.1817 129.994 65.6942C129.826 84.0073 125.895 103.112 112.387 115.56C99.2436 127.672 80.1264 129.268 62.244 128.128C45.6818 127.072 29.8226 121.277 18.1433 109.551C6.51995 97.8811 0.626111 82.11 0.0588862 65.6942C-0.530742 48.63 3.22642 31.1392 15.0047 18.7161C27.0834 5.97599 44.65 0.785576 62.244 0.104314Z"
                fill={_fill}
            />
        </svg>
    );
};
