import {ISVG} from "../../common/Logo/Logo";

export interface ICircle2Props extends Pick<ISVG, "fill"> {}

export const Circle2 = ({ fill }: ICircle2Props) => {
    const _fill = fill ?? "#FFEAD8";

    return (
        <svg
            width="484"
            height="495"
            viewBox="0 0 584 595"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M264.456 0.296597C398.223 -4.6912 535.577 53.1525 580.708 179.683C629.692 317.014 583.556 475.801 463.209 557.519C352.794 632.494 210.366 585.115 105.731 502.204C14.8607 430.2 -21.9505 311.626 13.0822 200.848C48.8905 87.6185 146.215 4.70548 264.456 0.296597Z"
                fill={_fill}
            />
        </svg>
    );
};
