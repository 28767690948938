// import { fromEvent, FileWithPath } from 'file-selector';

export async function openFileSelector(
  confg: UseFilePickerConfig
): Promise<string[]> {
  var selectedFiles: string[] = [];
  const fileExtensions =
    confg.accept instanceof Array ? confg.accept.join(",") : confg.accept;
  return new Promise(async (resolve, reject) => {
    var evt = await openFileDialog(fileExtensions!, true);

    try {
      const inputElement = evt.target as HTMLInputElement;
      var files = inputElement.files ? Array.from(inputElement.files) : [];
      for (var file of files) {
        const fileData = await readFile(file);
        selectedFiles.push(fileData as any);
      }
      resolve(selectedFiles);
    } catch (e) {
      console.log(e);
    }
  });
}

export async function readFile(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      // console.log(reader.result);
      resolve(reader.result as any);
    };
    reader.readAsDataURL(file);
  });
}

export async function openFileDialog(
  accept: string,
  multiple: boolean
  // callback: (arg: Event) => void
): Promise<Event> {
  return new Promise((resolve, reject) => {
    // this function must be called from a user
    // activation event (ie an onclick event)

    // Create an input element
    var inputElement = document.createElement("input");
    inputElement.setAttribute("class", "hidden");
    document.body.appendChild(inputElement);
    // Set its type to file
    inputElement.type = "file";
    // Set accept to the file types you want the user to select.
    // Include both the file extension and the mime type
    inputElement.accept = accept;
    // Accept multiple files
    inputElement.multiple = multiple;
    // set onchange event to call callback when user has selected file
    // inputElement.addEventListener("change", callback);
    inputElement.onchange = (event) => {
      console.log("inputElement.onchange ");
      resolve(event);
      document.body.removeChild(inputElement);
    };

    inputElement.onerror = (event) => {
      console.log("inputElement.onerror ");
      reject(event);
      document.body.removeChild(inputElement);
    };
    // set onblur event to call callback when user has selected file on Safari
    // inputElement.addEventListener("blur", callback);

    // dispatch a click event to open the file dialog

    inputElement.dispatchEvent(new MouseEvent("click"));
  });
}

export interface Options {
  /**Minimum file size in mb**/
  minFileSize?: number;
  /**Maximum file size in mb**/
  maxFileSize?: number;
}
export interface UseFilePickerConfig extends Options {
  accept?: string | string[];
  readAs?: ReadType;
  limitFilesConfig?: LimitFilesConfig;
}
export interface LimitFilesConfig {
  min?: number;
  max?: number;
}
export interface ImageDims {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

export type ReadType = "Text" | "BinaryString" | "ArrayBuffer" | "DataURL";
