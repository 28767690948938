import {ISVG} from "../../common/Logo/Logo";

export interface ICircle2Props extends Pick<ISVG, "fill"> {}

export const Circle2 = ({ fill }: ICircle2Props) => {
    const _fill = fill ?? "#F3F2E9";

    return (
        <svg
            width="129"
            height="128"
            viewBox="0 0 129 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
                d="M61.7652 0.103962C80.0421 -0.606819 99.3335 2.11595 112.439 14.8592C125.676 27.7295 129.163 47.0223 128.994 65.4723C128.827 83.7236 124.927 102.764 111.523 115.17C98.4802 127.241 79.51 128.831 61.7652 127.695C45.3304 126.642 29.5932 120.867 18.0038 109.181C6.46979 97.5506 0.621294 81.8327 0.0584333 65.4723C-0.52666 48.4658 3.2016 31.0341 14.8892 18.6529C26.8751 5.95581 44.3065 0.782923 61.7652 0.103962Z"
                fill={_fill}
            />
        </svg>
    );
};
