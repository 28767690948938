import {ISVG} from "../../common/Logo/Logo";

export interface ICircle1Props extends Pick<ISVG, "fill"> {}

export const Circle1 = ({ fill }: ICircle1Props) => {
    const _fill = fill ?? "#F3F2E9";

    return (
        <svg
            width="337"
            height="335"
            viewBox="0 0 337 335"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M161.356 0.272088C209.102 -1.58816 259.499 5.53783 293.737 38.8894C328.317 72.5733 337.425 123.066 336.985 171.353C336.549 219.12 326.359 268.952 291.342 301.421C257.27 333.013 207.712 337.176 161.356 334.202C118.421 331.447 77.3093 316.332 47.0331 285.747C16.9017 255.308 1.62307 214.171 0.152651 171.353C-1.37585 126.844 8.36387 81.222 38.8967 48.8182C70.2086 15.5875 115.747 2.04906 161.356 0.272088Z"
                fill={_fill}
            />
        </svg>
    );
};
