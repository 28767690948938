import RouteNames from "../../../Routes/RouteNames";
import {isSafari} from "react-device-detect";
import {openFileSelector} from "../../../helpers/file_picker";
import {useRouter} from "next/router";

export interface IStitchPicturesButtonProps {
  text?: string;
  onFileSelect?: () => void;
}

export const StitchPicturesButton = (props: IStitchPicturesButtonProps) => {
  const router = useRouter();

  async function handleFilePick(): Promise<boolean> {
    var files: string[] = await openFileSelector({
      accept: [".jpg", ".png", ".jpeg", ".gif", ".webp"],
    });
    // console.log(files.length);
    (window as any).state = {
      selectedFiles: files,
    };

    var iframe = document.getElementById("cm_iframe") as any;
    if (iframe != null) {
      iframe.contentWindow.postMessage({ fileLoaded: true }, "*");
    }

    return files.length != 0;
  }

  return (
    <div
      className="cursor-pointer inline-block bg-orange py-4 px-6 rounded-l-full rounded-r-full text-white shadow-sm"
      onClick={async () => {
        props.onFileSelect?.();
        if (isSafari) {
          var res = await handleFilePick();
          router.push(RouteNames.collageEditorStitch);
          if (res == true) {
            props.onFileSelect?.();
          }
        } else {
          router.push(RouteNames.collageEditorStitch);
          var res = await handleFilePick();
          if (res == true) {
            props.onFileSelect?.();
          }
        }
      }}
    >
      {props.text ?? "Merge Photos"}
    </div>
  );
};
